import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { useParams } from "react-router-dom";
import { getPiwigoAlbumImages } from "../../services/piwigo";
import useProjects from "../Projects/useProjects";

const useProject = () => {
  const urlParam = useParams();

  const albumId = parseInt(urlParam?.id ?? "0");

  const { data: piwigoAlbum, isLoading: isLoadingPiwigoAlbum } = useQuery({
    queryKey: [QUERY_KEYS.ALBUM, urlParam.id],
    queryFn: () => getPiwigoAlbumImages(albumId),
  });

  const { piwigoAlbums } = useProjects();

  const getPiwigoAlbumDetails = (albumId: number) => {
    return piwigoAlbums?.result.categories.find(
      (category) => category.id === albumId
    );
  };

  return {
    piwigoAlbum,
    isLoadingPiwigoAlbum,
    getPiwigoAlbumDetails,
  };
};

export default useProject;
