import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../constants";
import { getPiwigoImage } from "../services/piwigo";

const usePiwigoImage = (imageId: number) => {
  const { data: piwigoImage, isLoading: isLoadingPiwigoImage } = useQuery({
    queryKey: [QUERY_KEYS.IMAGE, imageId],
    queryFn: () => getPiwigoImage(imageId),
  });

  return { piwigoImage, isLoadingPiwigoImage };
};

export default usePiwigoImage;
