import { message } from "antd";
import axios from "axios";

export const STANDARD_GUTTER = 8;

export const PROJECT_DATE = "MM-YYYY";

export const PROJECT_DATE_DISPLAY = "MMMM YYYY";

export const axiosInstance = axios.create({
  baseURL: "https://piwigo.ataajy.lu/",
});

export const classNames = (classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const setPageTitle = (title: string) => {
  document.title = `${title} ― Ataajy`;
  scrollToTop();
};

export const scrollToTop = () => {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
};

export const handlePiwigoFailedRequest = () => {
  message.error("Échec du chargement de l'image");
};
