import { Image, List } from "antd";
import BlockHeader from "../BlockHeader";

type HomePageNews = {
  img: {
    alt: string;
    src: string;
  };
  title: string;
  teaser: string;
};

const newsList: HomePageNews[] = [
  {
    img: {
      alt: "Le président avec un pack de jus",
      src: "https://i1.wp.com/ataajy.lu/wp-content/uploads/2021/02/IMG-0bf74c8041d387ab54fe4bb52d3dd980-V.jpg?fit=576%2C1024&ssl=1",
    },
    teaser:
      "Une nouvelle mission humanitaire a été effectuée pendant ce mois de Février 2021 au Cameroun ...",
    title: "Voyage Humanitaire – Février 2021",
  },
];

const BlockNews = () => (
  <div className="block-news">
    <div className="text-center">
      <BlockHeader preTitle="Blog" title="Notre actualité" />
    </div>
    <List
      itemLayout="vertical"
      size="large"
      dataSource={newsList}
      renderItem={(item) => (
        <List.Item
          extra={<Image src={item.img.src} alt={item.img.alt} height={150} />}
        >
          <List.Item.Meta description={item.title} />
          {item.teaser}
        </List.Item>
      )}
    />
  </div>
);

export default BlockNews;
