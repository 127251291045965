import MainMenu from "../MainMenu";
import PageFooter from "../PageFooter";

type PageLayoutProps = {
  children: JSX.Element;
};

const PageLayout = ({ children }: PageLayoutProps) => (
  <div className="page-layout">
    <MainMenu />
    {children}
    <PageFooter />
  </div>
);

export default PageLayout;
