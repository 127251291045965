import { AxiosResponse } from "axios";
import { axiosInstance } from "../utils";
import type {
  AlbumImageResponse,
  PiwigoAlbumsResponse,
  PiwigoImageResponse,
} from "../types";

export const getPiwigoImage = (imageId: number) => {
  return axiosInstance
    .get("ws.php", {
      params: {
        format: "json",
        method: "pwg.images.getInfo",
        image_id: imageId,
      },
    })
    .then((response: AxiosResponse<PiwigoImageResponse>) => response.data);
};

export const getPiwigoAlbumImages = (albumId: number) => {
  return axiosInstance
    .get("ws.php", {
      params: {
        format: "json",
        method: "pwg.categories.getImages",
        cat_id: albumId,
      },
    })
    .then((response: AxiosResponse<AlbumImageResponse>) => response.data);
};

export const getPiwigoAlbums = () => {
  return axiosInstance
    .get("https://piwigo.ataajy.lu/ws.php", {
      params: {
        format: "json",
        method: "pwg.categories.getList",
      },
    })
    .then((response: AxiosResponse<PiwigoAlbumsResponse>) => response.data);
};
