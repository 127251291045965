import { Typography } from "antd";
import "./styles.less";

const { Text, Title } = Typography;

type BlockHeaderProps = {
  preTitle: string;
  title: string;
};

const BlockHeader = ({ preTitle, title }: BlockHeaderProps) => (
  <div className="block-header">
    <Text strong className="block-header__pre-title">
      {preTitle}
    </Text>
    <Title level={2} className="block-header__title">
      {title}
    </Title>
  </div>
);

export default BlockHeader;
