import { Col, Row, Statistic } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { STANDARD_GUTTER } from "../../utils";
import "./styles.less";

const SummaryInNumbers = () => (
  <div className="summary-in-numbers">
    <Row gutter={STANDARD_GUTTER * 3}>
      <Col xs={24} md={8}>
        <Statistic title="Volontaires" value={12} suffix={<PlusOutlined />} />
      </Col>
      <Col xs={24} md={8}>
        <Statistic
          title="Projets réalisés"
          value={8}
          suffix={<PlusOutlined />}
        />
      </Col>
      <Col xs={24} md={8}>
        <Statistic
          title="Voyages humanitaires"
          value={17}
          suffix={<PlusOutlined />}
        />
      </Col>
    </Row>
  </div>
);

export default SummaryInNumbers;
