import alainVisseEcran from "../assets/images/alain-visse-ecran.jpg";
import consultationDentaireFilleRobeBlanche2 from "../assets/images/consultation-dentaire-fille-robe-blanche-2.jpg";
import consultationDentaireFilleRobeBlanche from "../assets/images/consultation-dentaire-fille-robe-blanche.jpg";
import discoursAtelierDessin from "../assets/images/discours-atelier-dessin.jpg";
import discoursOpticien from "../assets/images/discours-opticien.jpg";
import discoursPresidentOrphelinat from "../assets/images/discussion-président-orphelinat.jpg";
import filleRobeBlancheDiscussionDentaire from "../assets/images/fille-robe-blanche-discussion-dentaire.jpg";
import hennieStander from "../assets/images/hennie-stander-p4jsgUB3F28-unsplash.jpg";
import imagePlaceholderFile from "../assets/images/image-placeholder.png";
import discussionOpticienKemass from "../assets/images/IMG-20220128-133043-opticien-kemass.jpg";
import adulteHandicapeOpticien from "../assets/images/IMG-adulte-handicape-opticien.jpg";
import enfantDeboutBequille from "../assets/images/IMG-debout-bequille.jpg";
import enfantBequilleRemiseLunette from "../assets/images/IMG-enfant-bequille-lunette.jpg";
import enfantHautRose from "../assets/images/IMG-enfant-haut-rose.jpg";
import enfantRobeLunette from "../assets/images/IMG-enfant-robe-lunettes.jpg";
import enfantRobePagneVesteOpticien from "../assets/images/IMG-enfant-robe-pagne-veste-opticien.jpg";
import enfantRobePagneVeste from "../assets/images/IMG-enfant-robe-pagne-veste.jpg";
import vivianeLunette from "../assets/images/IMG-viviane-lunette.jpg";
import opticienExplicationViviane from "../assets/images/IMG-viviane-opticien-explication.jpg";
import vivianeLunetteOpticien from "../assets/images/IMG-viviane-opticien.jpg";
import vivianeReceptionLunette from "../assets/images/IMG-viviane-reception-lunette.jpg";
import donateJo from "../assets/images/jo-szczepanska-donate-b69OOyN4-unsplash.jpg";
import kattYukawa from "../assets/images/katt-yukawa-K0E6E0a0R3A-unsplash.jpg";
import receptionOrdinateurs1 from "../assets/images/reception-ordinateurs-1.jpg";
import receptionOrdinateurs2 from "../assets/images/reception-ordinateurs-2.jpg";
import receptionOrdinateurs3 from "../assets/images/reception-ordinateurs-3.jpg";
import receptionOrdinateurs4 from "../assets/images/reception-ordinateurs-4.jpg";
import receptionOrdinateurs5 from "../assets/images/reception-ordinateurs-5.jpg";
import receptionOrdinateurs6 from "../assets/images/reception-ordinateurs-6.jpg";
import receptionOrdinateurs7 from "../assets/images/reception-ordinateurs-7.jpg";
import receptionOrdinateurs8 from "../assets/images/reception-ordinateurs-8.jpg";
import receptionOrdinateurs9 from "../assets/images/reception-ordinateurs-9.jpg";
import receptionOrdinateurs10 from "../assets/images/reception-ordinateurs-10.jpg";
import receptionOrdinateurs11 from "../assets/images/reception-ordinateurs-11.jpg";
import receptionOrdinateurs12 from "../assets/images/reception-ordinateurs-12.jpg";
import receptionOrdinateurs13 from "../assets/images/reception-ordinateurs-13.jpg";
import receptionOrdinateurs14 from "../assets/images/reception-ordinateurs-14.jpg";
import remiseLunetteFillePagne from "../assets/images/remise-lunette-fille-pagne.jpg";
import festival20230715151622 from "../assets/images/2023-food-music-festival/20230715_151622.jpg";
import festival20230715152945 from "../assets/images/2023-food-music-festival/20230715_152945.jpg";
import festival20230715173213 from "../assets/images/2023-food-music-festival/20230715_173213.jpg";
import festival20230715185518 from "../assets/images/2023-food-music-festival/20230715_185518.jpg";
import festival20230715185541 from "../assets/images/2023-food-music-festival/20230715_185541.jpg";
import festival20230715185702 from "../assets/images/2023-food-music-festival/20230715_185702.jpg";
import festival20230715185839 from "../assets/images/2023-food-music-festival/20230715_185839.jpg";
import festival20230715185908 from "../assets/images/2023-food-music-festival/20230715_185908.jpg";
import festival20230715185950 from "../assets/images/2023-food-music-festival/20230715_185950.jpg";
import festival20230715190857 from "../assets/images/2023-food-music-festival/20230715_190857.jpg";
import festival20230715200113 from "../assets/images/2023-food-music-festival/20230715_200113.jpg";
import festival20230716130206 from "../assets/images/2023-food-music-festival/20230716_130206.jpg";
import festival20230716132637 from "../assets/images/2023-food-music-festival/20230716_132637.jpg";
import festival20230716141545 from "../assets/images/2023-food-music-festival/20230716_141545.jpg";
import festival20230716141717 from "../assets/images/2023-food-music-festival/20230716_141717.jpg";
import festival20230716151711 from "../assets/images/2023-food-music-festival/20230716_151711.jpg";
import festival20230716162217 from "../assets/images/2023-food-music-festival/20230716_162217.jpg";
import festival20230716170234 from "../assets/images/2023-food-music-festival/20230716_170234(0).jpg";
import festivalCover from "../assets/images/2023-food-music-festival/festival-cover.jpg";
import tableMairie from "../assets/images/2023-ataajy-fevrier/don-mairie/IMG-2313163468802dc64a7fff7e406e94c8-V.jpg";
import imprimanteAvecMairie from "../assets/images/2023-ataajy-fevrier/don-mairie/IMG-3d3b7aa38e12a7e70f47764fe715f552-V.jpg";
import photoAvecAdjointMaire from "../assets/images/2023-ataajy-fevrier/don-mairie/IMG-6e8de0d73133fc6d5c501c5d225ad608-V.jpg";
import devantMairie from "../assets/images/2023-ataajy-fevrier/don-mairie/IMG-cd5ae2481e34776ec2beb313b769c7e7-V.jpg";
import e4aba722eace068688b54095cc from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-152897e4aba722eace068688b54095cc-V.jpg";
import fe76d6fcb4f1b1be802e80e3f7cb64 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-27fe76d6fcb4f1b1be802e80e3f7cb64-V.jpg";
import dbe08245dda6fb5a280148f33cb988c from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-4dbe08245dda6fb5a280148f33cb988c-V.jpg";
import f47fafa971d58312690f3cc2b98f35 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-54f47fafa971d58312690f3cc2b98f35-V.jpg";
import c8d4a7c579d3c928ba35dbb610f0740 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-5c8d4a7c579d3c928ba35dbb610f0740-V.jpg";
import fdc8d1d5c1e5770628d22cd2897b from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-6787fdc8d1d5c1e5770628d22cd2897b-V.jpg";
import c293942c87b96ee31799117cc9 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-798053c293942c87b96ee31799117cc9-V.jpg";
import a426fb9d7e08036ba6c7975bc4a5fd9 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-7a426fb9d7e08036ba6c7975bc4a5fd9-V.jpg";
import d0ac2455412f9515bc829ad575734b from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-81d0ac2455412f9515bc829ad575734b-V.jpg";
import f7aba18d0ec402c89cbe380cd5a308 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-86f7aba18d0ec402c89cbe380cd5a308-V.jpg";
import cbec3fd45ec3860635f9ef7a579738f from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-8cbec3fd45ec3860635f9ef7a579738f-V.jpg";
import aabe9679ff1d76e31f2ef93f174f2877 from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-aabe9679ff1d76e31f2ef93f174f2877-V.jpg";
import bcf8db31ea4b719d714a705ad2fe414b from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-bcf8db31ea4b719d714a705ad2fe414b-V.jpg";
import ee108e013449714b6fb5d565ebe280fa from "../assets/images/2023-ataajy-fevrier/don-kit-soin-dentaire/IMG-ee108e013449714b6fb5d565ebe280fa-V.jpg";
import d9831acd3b266223d62e769f6699140 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-0d9831acd3b266223d62e769f6699140-V.jpg";
import c059e3f4ef676ff508ec5df27b4868a from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-1c059e3f4ef676ff508ec5df27b4868a-V.jpg";
import fc3e2e2157320b1339137c1c149a6 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-235fc3e2e2157320b1339137c1c149a6-V.jpg";
import d789774a8f3f7c0846bd80da20 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-803636d789774a8f3f7c0846bd80da20-V.jpg";
import f1fbd32f604504894b7dfb2296d from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-88503f1fbd32f604504894b7dfb2296d-V.jpg";
import cdb19539a3024c45409ffbd0bbc02c6 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-9cdb19539a3024c45409ffbd0bbc02c6-V.jpg";
import a78050f609adcc2b527b25d8be1082c3 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-a78050f609adcc2b527b25d8be1082c3-V.jpg";
import b30584eb6b59e70ea06d1fca524cdee4 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-b30584eb6b59e70ea06d1fca524cdee4-V.jpg";
import befd28965a2298400a94cc5082c98f87 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-befd28965a2298400a94cc5082c98f87-V.jpg";
import c5329f9cf892b6b7ac1a9ea90cba8cae from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-c5329f9cf892b6b7ac1a9ea90cba8cae-V.jpg";
import cb2074b577043c15076e1c5787de7e28 from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-cb2074b577043c15076e1c5787de7e28-V.jpg";
import efb8f42753ef7233f9525e2399b3297a from "../assets/images/2023-ataajy-fevrier/visite-ecole/IMG-efb8f42753ef7233f9525e2399b3297a-V.jpg";
import photoGroupTripJune from "../assets/images/2022-06/photo-groupe.jpg";
import photoGroupTripJune1 from "../assets/images/2022-06/IMG-0f8490bd01977aff7b6ecb7588ea589c-V.jpg";
import photoGroupTripJune2 from "../assets/images/2022-06/IMG-2c5d0ca51b001fab0b196bbb75438ea8-V.jpg";
import photoGroupTripJune3 from "../assets/images/2022-06/IMG-3a326415a37da09c6f8a1992847c97e1-V.jpg";
import photoGroupTripJune4 from "../assets/images/2022-06/IMG-3e5ae35a55061db82e186bb7f85fc5a0-V.jpg";
import photoGroupTripJune5 from "../assets/images/2022-06/IMG-3e8c7437cb6a2cfdf202f3a86e9e875a-V.jpg";
import photoGroupTripJune6 from "../assets/images/2022-06/IMG-3e8867eec407b62bd9200626af72c75a-V.jpg";
import photoGroupTripJune7 from "../assets/images/2022-06/IMG-5f7c769f0701d9aa35da221104b392c4-V.jpg";
import photoGroupTripJune8 from "../assets/images/2022-06/IMG-6a102b1033dbe5a84d9869063b1d3408-V.jpg";
import photoGroupTripJune9 from "../assets/images/2022-06/IMG-06c91f86adc7eb1030883b61b8e1e4d1-V.jpg";
import photoGroupTripJune10 from "../assets/images/2022-06/IMG-06e0b9e019343637e7bc116c955e1843-V.jpg";
import photoGroupTripJune11 from "../assets/images/2022-06/IMG-8a216b8fb193a8d78b69fcbf044ee64d-V.jpg";
import photoGroupTripJune12 from "../assets/images/2022-06/IMG-08f041db4e42585c0a3a125d12945d69-V.jpg";
import photoGroupTripJune13 from "../assets/images/2022-06/IMG-9e7a4ec1604936a22ec319d4557bc8b1-V.jpg";
import photoGroupTripJune14 from "../assets/images/2022-06/IMG-12b718419a1e92f2aa660c6da81dafea-V.jpg";
import photoGroupTripJune15 from "../assets/images/2022-06/IMG-21a7442e2d3acb0b720c91d3194a68ec-V.jpg";
import photoGroupTripJune16 from "../assets/images/2022-06/IMG-25b4c6a246885b0c9524fcd35b2924b0-V.jpg";
import photoGroupTripJune17 from "../assets/images/2022-06/IMG-31b8883812359a62d7911b0024584404-V.jpg";
import photoGroupTripJune18 from "../assets/images/2022-06/IMG-36fa37974ac70494cfcb2db01f0080fd-V.jpg";
import photoGroupTripJune19 from "../assets/images/2022-06/IMG-46ac0b2075c04e5011db8dae4448aeb7-V.jpg";
import photoGroupTripJune20 from "../assets/images/2022-06/IMG-48d661708bfa71140e606e37c0e4c4b5-V.jpg";
import photoGroupTripJune21 from "../assets/images/2022-06/IMG-67bd7a6ce893de4fc818c32b3ef8f4b5-V.jpg";
import photoGroupTripJune22 from "../assets/images/2022-06/IMG-75a2f285b81c2890a0d4746fdeab68f0-V.jpg";
import photoGroupTripJune23 from "../assets/images/2022-06/IMG-85aaecac7414d7624065343c90b23c33-V.jpg";
import photoGroupTripJune24 from "../assets/images/2022-06/IMG-92b514961bc9f3c0731c02ea01ea401a-V.jpg";
import photoGroupTripJune25 from "../assets/images/2022-06/IMG-194f90d17af10d3bafd43738ffe480a3-V.jpg";
import photoGroupTripJune26 from "../assets/images/2022-06/IMG-304c26bbce9542cd85cf3d2ffc672847-V.jpg";
import photoGroupTripJune27 from "../assets/images/2022-06/IMG-321a1c97b7ad37ce1843f441f307bc47-V.jpg";
import photoGroupTripJune28 from "../assets/images/2022-06/IMG-0516c3fc5eb475c9173ea1da00c8aa25-V.jpg";
import photoGroupTripJune29 from "../assets/images/2022-06/IMG-546da816996f431b2322038879ae2c9f-V.jpg";
import photoGroupTripJune30 from "../assets/images/2022-06/IMG-780ec53b1c54259fb7c1e227bcfb4f3f-V.jpg";
import photoGroupTripJune31 from "../assets/images/2022-06/IMG-955d6a35d2833e61175f578d13e06bff-V.jpg";
import photoGroupTripJune32 from "../assets/images/2022-06/IMG-2721d3a17f011b3e66e2a2d630c5a761-V.jpg";
import photoGroupTripJune33 from "../assets/images/2022-06/IMG-3291b3f685e44820bdf4c521b87b6d58-V.jpg";
import photoGroupTripJune34 from "../assets/images/2022-06/IMG-3581a9078025494245d33f9221cfd8de-V.jpg";
import photoGroupTripJune35 from "../assets/images/2022-06/IMG-7983bf53acbb6ab798cf54e7edf2150a-V.jpg";
import photoGroupTripJune36 from "../assets/images/2022-06/IMG-9883b01d505dd5631fbad2a760fe31f2-V.jpg";
import photoGroupTripJune37 from "../assets/images/2022-06/IMG-31256edbce13521f68440c24e45f9198-V.jpg";
import photoGroupTripJune38 from "../assets/images/2022-06/IMG-55883ff8690d0e8031988f3ed991024e-V.jpg";
import photoGroupTripJune39 from "../assets/images/2022-06/IMG-88902fce20d5dca18b2a79ba9bebfae8-V.jpg";
import photoGroupTripJune40 from "../assets/images/2022-06/IMG-89804b5e99bcd3449e408a0aaf1ceda5-V.jpg";
import photoGroupTripJune41 from "../assets/images/2022-06/IMG-365821e37604b31565d5314b5adf10fb-V.jpg";
import photoGroupTripJune42 from "../assets/images/2022-06/IMG-0727064b6bf0c29d1622cf9439f37b14-V.jpg";
import photoGroupTripJune43 from "../assets/images/2022-06/IMG-02826865f4e663ad28f692aaf1806204-V.jpg";
import photoGroupTripJune44 from "../assets/images/2022-06/IMG-a7ca1c0cdfd37163d767cced0811b115-V.jpg";
import photoGroupTripJune45 from "../assets/images/2022-06/IMG-a95e2de00c3e3a1f3e2e85497cf87cf0-V.jpg";
import photoGroupTripJune46 from "../assets/images/2022-06/IMG-a137525b739aa9e9e05b988c7c1c45c5-V.jpg";
import photoGroupTripJune47 from "../assets/images/2022-06/IMG-ad4e04d14557355f1097dc9f3b132bd5-V.jpg";
import photoGroupTripJune48 from "../assets/images/2022-06/IMG-b5bb31d718543306695535ac9cecabd5-V.jpg";
import photoGroupTripJune49 from "../assets/images/2022-06/IMG-b96166da827d10fc61c39f1cd6d48d7d-V.jpg";
import photoGroupTripJune50 from "../assets/images/2022-06/IMG-bffb0ea68c7a2a0e2aef542ff8f10616-V.jpg";
import photoGroupTripJune51 from "../assets/images/2022-06/IMG-c5c778f60e3e00076406dc19898bc5ca-V.jpg";
import photoGroupTripJune52 from "../assets/images/2022-06/IMG-d9b11e25ba9e2db4e7110a9132e8b5e6-V.jpg";
import photoGroupTripJune53 from "../assets/images/2022-06/IMG-d46eaa12f8736b42dae31d362e7a38e6-V.jpg";
import photoGroupTripJune54 from "../assets/images/2022-06/IMG-dab2eb9f07309739bcb9f220e95c1d63-V.jpg";
import photoGroupTripJune55 from "../assets/images/2022-06/IMG-de5d9271866217c5aa62aaa2d6ccdbf8-V.jpg";
import photoGroupTripJune56 from "../assets/images/2022-06/IMG-e9958a14df4d234c9df8efa0d09df0e9-V.jpg";
import photoGroupTripJune57 from "../assets/images/2022-06/IMG-f6ce7688ecd3cbf2cc77661ea0295cfb-V.jpg";
import photoGroupTripJune58 from "../assets/images/2022-06/IMG-f9fbeedd230edac217c063d428987963-V.jpg";
import photoGroupTripJune59 from "../assets/images/2022-06/IMG-f398faf1e9ff10d2c319b33d8b2809cd-V.jpg";
import photoGroupTripJune60 from "../assets/images/2022-06/IMG-f54309bb8d326852d2a40023845c5182-V.jpg";
import photoGroupTripJune61 from "../assets/images/2022-06/IMG-fed8d45dbe008ad450d936e8344c6852-V.jpg";
import photoGroupTripJune62 from "../assets/images/2022-06/IMG-ffee9b855340939ca1440a02f8520baa-V.jpg";
import { ImageItem } from "../types/main";

// Generate ID: Date.now()

const list: ImageItem[] = [
  {
    id: "efb8f42753ef7233f9525e2399b3297a",
    src: efb8f42753ef7233f9525e2399b3297a,
    alt: "Visite école 12",
  },
  {
    id: "cb2074b577043c15076e1c5787de7e28",
    src: cb2074b577043c15076e1c5787de7e28,
    alt: "Visite école 11",
  },
  {
    id: "c5329f9cf892b6b7ac1a9ea90cba8cae",
    src: c5329f9cf892b6b7ac1a9ea90cba8cae,
    alt: "Visite école 10",
  },
  {
    id: "befd28965a2298400a94cc5082c98f87",
    src: befd28965a2298400a94cc5082c98f87,
    alt: "Visite école 9",
  },
  {
    id: "b30584eb6b59e70ea06d1fca524cdee4",
    src: b30584eb6b59e70ea06d1fca524cdee4,
    alt: "Visite école 8",
  },
  {
    id: "a78050f609adcc2b527b25d8be1082c3",
    src: a78050f609adcc2b527b25d8be1082c3,
    alt: "Visite école 7",
  },
  {
    id: "cdb19539a3024c45409ffbd0bbc02c6",
    src: cdb19539a3024c45409ffbd0bbc02c6,
    alt: "Visite école 6",
  },
  {
    id: "d9831acd3b266223d62e769f6699140",
    src: d9831acd3b266223d62e769f6699140,
    alt: "Visite école 1",
  },
  {
    id: "c059e3f4ef676ff508ec5df27b4868a",
    src: c059e3f4ef676ff508ec5df27b4868a,
    alt: "Visite école 2",
  },
  {
    id: "fc3e2e2157320b1339137c1c149a6",
    src: fc3e2e2157320b1339137c1c149a6,
    alt: "Visite école 3",
  },
  {
    id: "d789774a8f3f7c0846bd80da20",
    src: d789774a8f3f7c0846bd80da20,
    alt: "Visite école 4",
  },
  {
    id: "f1fbd32f604504894b7dfb2296d",
    src: f1fbd32f604504894b7dfb2296d,
    alt: "Visite école 5",
  },

  {
    id: "e4aba722eace068688b54095cc",
    src: e4aba722eace068688b54095cc,
    alt: "Don de kit dentaire 1",
  },
  {
    id: "fe76d6fcb4f1b1be802e80e3f7cb64",
    src: fe76d6fcb4f1b1be802e80e3f7cb64,
    alt: "Don de kit dentaire 2",
  },
  {
    id: "dbe08245dda6fb5a280148f33cb988c",
    src: dbe08245dda6fb5a280148f33cb988c,
    alt: "Don de kit dentaire 3",
  },
  {
    id: "f47fafa971d58312690f3cc2b98f35",
    src: f47fafa971d58312690f3cc2b98f35,
    alt: "Don de kit dentaire 4",
  },
  {
    id: "c8d4a7c579d3c928ba35dbb610f0740",
    src: c8d4a7c579d3c928ba35dbb610f0740,
    alt: "Don de kit dentaire 5",
  },
  {
    id: "fdc8d1d5c1e5770628d22cd2897b",
    src: fdc8d1d5c1e5770628d22cd2897b,
    alt: "Don de kit dentaire 6",
  },
  {
    id: "c293942c87b96ee31799117cc9",
    src: c293942c87b96ee31799117cc9,
    alt: "Don de kit dentaire 7",
  },
  {
    id: "a426fb9d7e08036ba6c7975bc4a5fd9",
    src: a426fb9d7e08036ba6c7975bc4a5fd9,
    alt: "Don de kit dentaire 8",
  },
  {
    id: "d0ac2455412f9515bc829ad575734b",
    src: d0ac2455412f9515bc829ad575734b,
    alt: "Don de kit dentaire 9",
  },
  {
    id: "f7aba18d0ec402c89cbe380cd5a308",
    src: f7aba18d0ec402c89cbe380cd5a308,
    alt: "Don de kit dentaire 10",
  },
  {
    id: "cbec3fd45ec3860635f9ef7a579738f",
    src: cbec3fd45ec3860635f9ef7a579738f,
    alt: "Don de kit dentaire 11",
  },
  {
    id: "aabe9679ff1d76e31f2ef93f174f2877",
    src: aabe9679ff1d76e31f2ef93f174f2877,
    alt: "Don de kit dentaire 12",
  },
  {
    id: "bcf8db31ea4b719d714a705ad2fe414b",
    src: bcf8db31ea4b719d714a705ad2fe414b,
    alt: "Don de kit dentaire 13",
  },
  {
    id: "ee108e013449714b6fb5d565ebe280fa",
    src: ee108e013449714b6fb5d565ebe280fa,
    alt: "Don de kit dentaire 14",
  },
  {
    id: "1689876349930",
    src: tableMairie,
    alt: "Réunion à la mairie",
  },
  {
    id: "1689876465897",
    src: imprimanteAvecMairie,
    alt: "Imprmante offerte à la mairie",
  },
  {
    id: "1689876516653",
    src: photoAvecAdjointMaire,
    alt: "Adjoint de la mairie avec les membres d'Ataajy",
  },
  {
    id: "1689876553002",
    src: devantMairie,
    alt: "Photo prise devant la mairie",
  },
  {
    id: "1645383986581",
    src: remiseLunetteFillePagne,
    alt: "Remise de lunettes à une fille portant un pagne",
  },
  {
    id: "1645557540735",
    src: donateJo,
    alt: "Donate",
  },
  {
    id: "1645561116410",
    src: kattYukawa,
    alt: "Katt Yukawa",
  },
  {
    id: "1646449685819",
    src: discussionOpticienKemass,
    alt: "Kemass derrière opticien",
  },
  {
    id: "1646450506128",
    src: enfantHautRose,
    alt: "Enfant avec haut rose",
  },
  {
    id: "1646451329824",
    src: enfantRobePagneVeste,
    alt: "Enfant avec robe pagne et veste",
  },
  {
    id: "1646451513447",
    src: enfantDeboutBequille,
    alt: "Enfant debout avec béquille",
  },
  {
    id: "1646451676149",
    src: vivianeLunette,
    alt: "Vivianne avec lunette",
  },
  {
    id: "1646451862979",
    src: vivianeLunetteOpticien,
    alt: "Vivianne avec opticien",
  },
  {
    id: "1646452006865",
    src: enfantRobePagneVesteOpticien,
    alt: "Enfant robe pagne avec opticien",
  },
  {
    id: "1646452159696",
    src: opticienExplicationViviane,
    alt: "Opticien explication à Viviane",
  },
  {
    id: "1646452302607",
    src: enfantBequilleRemiseLunette,
    alt: "Enfant béquille remise lunette",
  },
  {
    id: "1646452465421",
    src: adulteHandicapeOpticien,
    alt: "Adulte handicapé avec opticien",
  },
  {
    id: "1646452578246",
    src: vivianeReceptionLunette,
    alt: "Viviane réception lunettes",
  },
  {
    id: "1646452697955",
    src: enfantRobeLunette,
    alt: "Enfant robe réception lunettes",
  },
  {
    id: "1646455366852",
    src: hennieStander,
    alt: "Hennie Stander unsplash",
  },
  {
    id: "1648156314993",
    src: consultationDentaireFilleRobeBlanche,
    alt: "Consultaire dentaire",
  },
  {
    id: "1648157232354",
    src: consultationDentaireFilleRobeBlanche2,
    alt: "Consultaire dentaire",
  },
  {
    id: "1648157392307",
    src: filleRobeBlancheDiscussionDentaire,
    alt: "Fille discussion dentaire",
  },
  {
    id: "1648157840553",
    src: alainVisseEcran,
    alt: "Alain visse un écran",
  },
  {
    id: "1648158258111",
    src: receptionOrdinateurs1,
    alt: "Réception d'ordinateurs 1",
  },
  {
    id: "1648158594531",
    src: receptionOrdinateurs2,
    alt: "Réception d'ordinateurs 2",
  },
  {
    id: "1648158617412",
    src: receptionOrdinateurs3,
    alt: "Réception d'ordinateurs 3",
  },
  {
    id: "1648158640636",
    src: receptionOrdinateurs4,
    alt: "Réception d'ordinateurs 4",
  },
  {
    id: "1648158668843",
    src: receptionOrdinateurs5,
    alt: "Réception d'ordinateurs 5",
  },
  {
    id: "1648158690506",
    src: receptionOrdinateurs6,
    alt: "Réception d'ordinateurs 6",
  },
  {
    id: "1648158709142",
    src: receptionOrdinateurs7,
    alt: "Réception d'ordinateurs 7",
  },
  {
    id: "1648158728410",
    src: receptionOrdinateurs8,
    alt: "Réception d'ordinateurs 8",
  },
  {
    id: "1648158747966",
    src: receptionOrdinateurs9,
    alt: "Réception d'ordinateurs 9",
  },
  {
    id: "1648158767918",
    src: receptionOrdinateurs10,
    alt: "Réception d'ordinateurs 10",
  },
  {
    id: "1648158787419",
    src: receptionOrdinateurs11,
    alt: "Réception d'ordinateurs 11",
  },
  {
    id: "1648158806983",
    src: receptionOrdinateurs12,
    alt: "Réception d'ordinateurs 12",
  },
  {
    id: "1648158829002",
    src: receptionOrdinateurs13,
    alt: "Réception d'ordinateurs 13",
  },
  {
    id: "1648158847925",
    src: receptionOrdinateurs14,
    alt: "Réception d'ordinateurs 14",
  },
  {
    id: "1689798003074",
    src: festival20230715151622,
    alt: "Festival 'Food & Music' 2023 - 1",
  },
  {
    id: "1689798129820",
    src: festival20230715152945,
    alt: "Festival 'Food & Music' 2023 - 2",
  },
  {
    id: "1689798163739",
    src: festival20230715173213,
    alt: "Festival 'Food & Music' 2023 - 3",
  },
  {
    id: "1689798197490",
    src: festival20230715185518,
    alt: "Festival 'Food & Music' 2023 - 4",
  },
  {
    id: "1689798236907",
    src: festival20230715185541,
    alt: "Festival 'Food & Music' 2023 - 5",
  },
  {
    id: "1689798267548",
    src: festival20230715185702,
    alt: "Festival 'Food & Music' 2023 - 6",
  },
  {
    id: "1689798302114",
    src: festival20230715185839,
    alt: "Festival 'Food & Music' 2023 - 7",
  },
  {
    id: "1689798336499",
    src: festival20230715185908,
    alt: "Festival 'Food & Music' 2023 - 8",
  },
  {
    id: "1689798385214",
    src: festival20230715185950,
    alt: "Festival 'Food & Music' 2023 - 9",
  },
  {
    id: "1689798414525",
    src: festival20230715190857,
    alt: "Festival 'Food & Music' 2023 - 10",
  },
  {
    id: "1689798446352",
    src: festival20230715200113,
    alt: "Festival 'Food & Music' 2023 - 11",
  },
  {
    id: "1689798480009",
    src: festival20230716130206,
    alt: "Festival 'Food & Music' 2023 - 12",
  },
  {
    id: "1689798516062",
    src: festival20230716132637,
    alt: "Festival 'Food & Music' 2023 - 13",
  },
  {
    id: "1689798544657",
    src: festival20230716141545,
    alt: "Festival 'Food & Music' 2023 - 14",
  },
  {
    id: "1689798579381",
    src: festival20230716141717,
    alt: "Festival 'Food & Music' 2023 - 15",
  },
  {
    id: "1689798610074",
    src: festival20230716151711,
    alt: "Festival 'Food & Music' 2023 - 16",
  },
  {
    id: "1689798640404",
    src: festival20230716162217,
    alt: "Festival 'Food & Music' 2023 - 17",
  },
  {
    id: "1689798669448",
    src: festival20230716170234,
    alt: "Festival 'Food & Music' 2023 - 18",
  },
  {
    id: "1689799303715",
    src: festivalCover,
    alt: "Festival 'Food & Music' 2023 - 19",
  },
  {
    id: "1656137955582",
    src: photoGroupTripJune62,
    alt: "Trip photo 62",
  },
  {
    id: "1656137934197",
    src: photoGroupTripJune61,
    alt: "Trip photo 61",
  },
  {
    id: "1656137915289",
    src: photoGroupTripJune60,
    alt: "Trip photo 60",
  },
  {
    id: "1656137886896",
    src: photoGroupTripJune59,
    alt: "Trip photo 59",
  },
  {
    id: "1656137862696",
    src: photoGroupTripJune58,
    alt: "Trip photo 58",
  },
  {
    id: "1656137842401",
    src: photoGroupTripJune57,
    alt: "Trip photo 57",
  },
  {
    id: "1656137819550",
    src: photoGroupTripJune56,
    alt: "Trip photo 56",
  },
  {
    id: "1656137800767",
    src: photoGroupTripJune55,
    alt: "Trip photo 55",
  },
  {
    id: "1656137779363",
    src: photoGroupTripJune54,
    alt: "Trip photo 54",
  },
  {
    id: "1656137760881",
    src: photoGroupTripJune53,
    alt: "Trip photo 53",
  },
  {
    id: "1656137740553",
    src: photoGroupTripJune52,
    alt: "Trip photo 52",
  },
  {
    id: "1656137723725",
    src: photoGroupTripJune51,
    alt: "Trip photo 51",
  },
  {
    id: "1656137695274",
    src: photoGroupTripJune50,
    alt: "Trip photo 50",
  },
  {
    id: "1656137669742",
    src: photoGroupTripJune49,
    alt: "Trip photo 49",
  },
  {
    id: "1656137642391",
    src: photoGroupTripJune48,
    alt: "Trip photo 48",
  },
  {
    id: "1656137620548",
    src: photoGroupTripJune47,
    alt: "Trip photo 47",
  },
  {
    id: "1656137600169",
    src: photoGroupTripJune46,
    alt: "Trip photo 46",
  },
  {
    id: "1656137578924",
    src: photoGroupTripJune45,
    alt: "Trip photo 45",
  },
  {
    id: "1656137553906",
    src: photoGroupTripJune44,
    alt: "Trip photo 44",
  },
  {
    id: "1656137533447",
    src: photoGroupTripJune43,
    alt: "Trip photo 43",
  },
  {
    id: "1656137507321",
    src: photoGroupTripJune42,
    alt: "Trip photo 42",
  },
  {
    id: "1656137483051",
    src: photoGroupTripJune41,
    alt: "Trip photo 41",
  },
  {
    id: "1656137458337",
    src: photoGroupTripJune40,
    alt: "Trip photo 40",
  },
  {
    id: "1656137438510",
    src: photoGroupTripJune39,
    alt: "Trip photo 39",
  },
  {
    id: "1656137414369",
    src: photoGroupTripJune38,
    alt: "Trip photo 38",
  },
  {
    id: "1656137355801",
    src: photoGroupTripJune37,
    alt: "Trip photo 37",
  },
  {
    id: "1656137334825",
    src: photoGroupTripJune36,
    alt: "Trip photo 36",
  },
  {
    id: "1656137305827",
    src: photoGroupTripJune35,
    alt: "Trip photo 35",
  },
  {
    id: "1656137285108",
    src: photoGroupTripJune34,
    alt: "Trip photo 34",
  },
  {
    id: "1656137264809",
    src: photoGroupTripJune33,
    alt: "Trip photo 33",
  },
  {
    id: "1656137243241",
    src: photoGroupTripJune32,
    alt: "Trip photo 32",
  },
  {
    id: "1656137225018",
    src: photoGroupTripJune31,
    alt: "Trip photo 31",
  },
  {
    id: "1656137200505",
    src: photoGroupTripJune30,
    alt: "Trip photo 30",
  },
  {
    id: "1656137177343",
    src: photoGroupTripJune29,
    alt: "Trip photo 29",
  },
  {
    id: "1656137147311",
    src: photoGroupTripJune28,
    alt: "Trip photo 28",
  },
  {
    id: "1656137125513",
    src: photoGroupTripJune27,
    alt: "Trip photo 27",
  },
  {
    id: "1656137097122",
    src: photoGroupTripJune26,
    alt: "Trip photo 26",
  },
  {
    id: "1656137074281",
    src: photoGroupTripJune25,
    alt: "Trip photo 25",
  },
  {
    id: "1656137049907",
    src: photoGroupTripJune24,
    alt: "Trip photo 24",
  },
  {
    id: "1656137023494",
    src: photoGroupTripJune23,
    alt: "Trip photo 23",
  },
  {
    id: "1656137003082",
    src: photoGroupTripJune22,
    alt: "Trip photo 22",
  },
  {
    id: "1656136980425",
    src: photoGroupTripJune21,
    alt: "Trip photo 21",
  },
  {
    id: "1656136960723",
    src: photoGroupTripJune20,
    alt: "Trip photo 20",
  },
  {
    id: "1656136921341",
    src: photoGroupTripJune19,
    alt: "Trip photo 19",
  },
  {
    id: "1656136899871",
    src: photoGroupTripJune18,
    alt: "Trip photo 18",
  },
  {
    id: "1656136869692",
    src: photoGroupTripJune17,
    alt: "Trip photo 17",
  },
  {
    id: "1656136852367",
    src: photoGroupTripJune16,
    alt: "Trip photo 16",
  },
  {
    id: "1656136828881",
    src: photoGroupTripJune15,
    alt: "Trip photo 15",
  },
  {
    id: "1656136810014",
    src: photoGroupTripJune14,
    alt: "Trip photo 14",
  },
  {
    id: "1656136791276",
    src: photoGroupTripJune13,
    alt: "Trip photo 13",
  },
  {
    id: "1656136765907",
    src: photoGroupTripJune12,
    alt: "Trip photo 12",
  },
  {
    id: "1656136742629",
    src: photoGroupTripJune11,
    alt: "Trip photo 11",
  },
  {
    id: "1656136721491",
    src: photoGroupTripJune10,
    alt: "Trip photo 10",
  },
  {
    id: "1656136696493",
    src: photoGroupTripJune9,
    alt: "Trip photo 9",
  },
  {
    id: "1656136631808",
    src: photoGroupTripJune8,
    alt: "Trip photo 8",
  },
  {
    id: "1656136515968",
    src: photoGroupTripJune7,
    alt: "Trip photo 7",
  },
  {
    id: "1656136494720",
    src: photoGroupTripJune6,
    alt: "Trip photo 6",
  },
  {
    id: "1656136475532",
    src: photoGroupTripJune5,
    alt: "Trip photo 5",
  },
  {
    id: "1656136447146",
    src: photoGroupTripJune4,
    alt: "Trip photo 4",
  },
  {
    id: "1656136408426",
    src: photoGroupTripJune3,
    alt: "Trip photo 3",
  },
  {
    id: "1656136337809",
    src: photoGroupTripJune2,
    alt: "Trip photo 2",
  },
  {
    id: "1656135611944",
    src: photoGroupTripJune1,
    alt: "Bénévoles - enfant avec capuche",
  },
  {
    id: "1656134419804",
    src: photoGroupTripJune,
    alt: "Voyage Juin 2022 - Groupe",
  },
];

export const imagePlaceholder = {
  id: "1645384114755",
  src: imagePlaceholderFile,
  alt: "image inconnu",
};

export const getImageById = (id: string) => {
  const imageFound = list.find((image) => image.id === id);

  return imageFound ?? imagePlaceholder;
};
