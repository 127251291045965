import { Card, Carousel } from "antd";
import equipeAtaajySansYves from "../../assets/images/equipe-ataajy-sans-yves.jpg";
import presidentOrphelinatPresident from "../../assets/images/president-orphelinat-president-association.jpg";
import enfantAvecBrosseDent from "../../assets/images/Slider-Don-Kit-Soins-Dentaires.png";
import festivalCover from "../../assets/images/2023-food-music-festival/festival-cover.jpg";
import donCflCover from "../../assets/images/2023-12/don-cfl.png";
import "./styles.less";

const { Meta } = Card;

const HomepageSlider = () => {
  return (
    <Carousel autoplay={false} className="homepage-slider">
      <Card
        cover={<img src={donCflCover} alt="Don Ordinateurs CFL - 2023" />}
        className="homepage-slider__card"
      >
        <Meta title="Don Ordinateurs CFL - 2023" />
      </Card>
      <Card
        cover={
          <img src={festivalCover} alt="Festival 'Food & Festival' 2023" />
        }
        className="homepage-slider__card"
      >
        <Meta title="Festival 'Food & Festival' 2023" />
      </Card>
      <Card
        cover={
          <img
            src={enfantAvecBrosseDent}
            alt="Distribution de kit de soins dentaires"
          />
        }
        className="homepage-slider__card"
      >
        <Meta title="Distribution de kit de soins dentaires" />
      </Card>
      <Card
        cover={
          <img
            src={presidentOrphelinatPresident}
            alt="Président de l'orphelinat"
          />
        }
        className="homepage-slider__card"
      >
        <Meta title="Président de l'orphelinat (droite) et Yves" />
      </Card>
      <Card
        cover={<img src={equipeAtaajySansYves} alt="Équipe Ataajy sans Yves" />}
        className="homepage-slider__card"
      >
        <Meta title="L'équipe Ataajy sans son président" />
      </Card>
    </Carousel>
  );
};

export default HomepageSlider;
