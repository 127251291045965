import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoaderAnimation from "../../components/LoaderAnimation";
import LocalProject from "../../components/LocalProject";
import PiwigoProject from "../../components/PiwigoProject";
import { getImageById } from "../../getters/images";
import { getCompletedProject } from "../../getters/projectsCompleted";
import { getPiwigoAlbumImages } from "../../services/piwigo";
import {
  AlbumImageResponse,
  ImageItem,
  PiwigoCategoryImage,
  ProjectItem,
} from "../../types";
import { handlePiwigoFailedRequest, setPageTitle } from "../../utils";
import { ROUTES } from "../../utils/enums";
import "./styles.less";
import useProject from "./useProject";

type ProjectPageState = {
  isLoading: boolean;
  piwigoImages: PiwigoCategoryImage[];
  projectImage?: ImageItem;
  selectedProject?: ProjectItem;
  selectedPiwigoAlbum?: AlbumImageResponse;
};

const Project = () => {
  const [componentState, setComponentState] = useState<ProjectPageState>({
    isLoading: true,
    piwigoImages: [],
  });
  const urlParam = useParams();
  const navigate = useNavigate();

  const { isLoadingPiwigoAlbum, piwigoAlbum, getPiwigoAlbumDetails } =
    useProject();

  const breadcrumbs = [
    { link: ROUTES.PROJECTS, value: "Projets" },
    { value: componentState.selectedProject?.title ?? "" },
  ];

  const loadCompletedProjects = () => {
    if (!isLoadingPiwigoAlbum) {
      const projectFound = getCompletedProject(urlParam?.id ?? "0");
      const piwigoAlbumDetails = getPiwigoAlbumDetails(
        parseInt(urlParam?.id ?? "0")
      );

      if (piwigoAlbum && piwigoAlbumDetails) {
        setComponentState((prevState) => ({
          ...prevState,
          isLoading: false,
          projectImage: {
            alt: piwigoAlbumDetails.name,
            id: piwigoAlbum.result.images[0].id.toString(),
            src: piwigoAlbum.result.images[0].element_url,
          },
          selectedPiwigoAlbum: piwigoAlbum,
          selectedProject: undefined,
        }));
      } else if (projectFound) {
        setComponentState((prevState) => ({
          ...prevState,
          isLoading: false,
          projectImage: getImageById(projectFound.coverImageId),
          selectedProject: projectFound,
          selectedPiwigoAlbum: undefined,
        }));
        setPageTitle(projectFound.title);
      } else {
        navigate(ROUTES.PROJECTS);
      }
    }
  };

  useEffect(() => {
    loadCompletedProjects();
  }, [isLoadingPiwigoAlbum]); // eslint-disable-line

  useEffect(() => {
    if (
      typeof componentState.selectedProject?.albumId === "number" &&
      componentState.selectedProject?.albumId !== 0
    ) {
      setComponentState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      getPiwigoAlbumImages(componentState.selectedProject?.albumId)
        .then((response) => {
          setComponentState((prevState) => ({
            ...prevState,
            isLoading: false,
            projectImage: {
              alt: response.result.images[0].name,
              id: response.result.images[0].id.toString(),
              src: response.result.images[0].derivatives.xlarge.url,
            },
            piwigoImages: response.result.images,
          }));
        })
        .catch(() => {
          handlePiwigoFailedRequest();
          setComponentState((prevState) => ({
            ...prevState,
            isLoading: false,
          }));
        });
    }
  }, [componentState.selectedProject]);

  const renderContent = () => {
    const { selectedProject, projectImage, selectedPiwigoAlbum } =
      componentState;

    if (selectedProject && projectImage) {
      return (
        <LocalProject
          breadcrumbs={breadcrumbs}
          projectImage={projectImage}
          currentProject={selectedProject}
        />
      );
    } else if (selectedPiwigoAlbum && projectImage) {
      return (
        <PiwigoProject
          projectImage={projectImage}
          currentProject={selectedPiwigoAlbum}
        />
      );
    }

    return <></>;
  };

  return (
    <div className="project">
      {componentState.isLoading ? <LoaderAnimation /> : renderContent()}
    </div>
  );
};

export default Project;
