import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import logoAtaajy from "../../assets/images/ataajy-logo.png";
import { STANDARD_GUTTER } from "../../utils";
import { ROUTES } from "../../utils/enums";
import AtaajyBankAccount from "../AtaajyBankAccount";
import StandardPageBlock from "../StandardPageBlock";
import "./styles.less";

const { Title, Text } = Typography;

const PageFooter = () => {
  const today = new Date();

  return (
    <div className="page-footer">
      <StandardPageBlock>
        <>
          <Row
            gutter={STANDARD_GUTTER * 2}
            align="bottom"
            className="page-footer__row-1"
          >
            <Col span={0} md={10}>
              <Link to={ROUTES.HOMEPAGE}>
                <img src={logoAtaajy} alt="Logo Ataajy" />
              </Link>
            </Col>
            <Col span={0} md={4} />
            <Col span={0} md={10}>
              <Title level={3}>Contact</Title>
            </Col>
          </Row>

          <Row gutter={STANDARD_GUTTER * 2} className="page-footer__row-2">
            <Col span={24} md={10}>
              <p className="page-footer__row-2__logo">
                <Link to={ROUTES.HOMEPAGE}>
                  <img src={logoAtaajy} alt="Logo Ataajy" />
                </Link>
              </p>
              <p>
                Ataajy a.s.b.l. est une association fondée et basée au
                Luxembourg
              </p>
              <p>
                <a
                  href="https://www.facebook.com/ataajy.ataajy"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookOutlined />
                </a>
                <a
                  href="https://www.instagram.com/ataajylu/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramOutlined />
                </a>
              </p>
            </Col>
            <Col span={0} md={4} />
            <Col span={24} md={10}>
              <Title level={3} className="page-footer__row-2__title">
                Contact
              </Title>
              <p>
                Vous pouvez nous contacter par mail à l'adresse suivante:
                <Text strong> contact(at)ataajy.lu</Text>
              </p>
              <AtaajyBankAccount isFooter />
            </Col>
          </Row>
          <Divider />
          <p className="text-center">
            © Copyright {today.getFullYear()} | Ataajy a.s.b.l. | Tous droits
            réservés
          </p>
        </>
      </StandardPageBlock>
    </div>
  );
};

export default PageFooter;
