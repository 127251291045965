import { Descriptions, Typography } from "antd";
import "./styles.less";

const { Text } = Typography;

type AtaajyBankAccountProps = {
  isFooter?: boolean;
};

const AtaajyBankAccount = ({ isFooter }: AtaajyBankAccountProps) => (
  <Descriptions
    title="Ataajy asbl"
    className={`ataajy-bank-account ${isFooter ? "small" : ""}`}
  >
    <Descriptions.Item label="IBAN" span={3}>
      <Text strong>LU17 1111 7055 5877 0000</Text>
    </Descriptions.Item>
    <Descriptions.Item label="BIC" span={3}>
      <Text strong>CCPL LULL</Text>
    </Descriptions.Item>
  </Descriptions>
);

export default AtaajyBankAccount;
