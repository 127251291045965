import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "../../constants";
import { getPiwigoAlbums } from "../../services/piwigo";

const useProjects = () => {
  const { data: piwigoAlbums, isLoading: isLoadingPiwigoAlbums } = useQuery({
    queryKey: [QUERY_KEYS.ALBUMS],
    queryFn: getPiwigoAlbums,
  });

  return { piwigoAlbums, isLoadingPiwigoAlbums };
};

export default useProjects;
