import { Button, Card } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getImageById, imagePlaceholder } from "../../getters/images";
import { ROUTES } from "../../utils/enums";
import { ImageItem, ProjectItem } from "../../types/main";
import "./styles.less";
import { getPiwigoImage } from "../../services/piwigo";
import { handlePiwigoFailedRequest } from "../../utils";

const { Meta } = Card;

type ProjectProps = {
  item: ProjectItem;
};

const ProjectTeaser = ({ item }: ProjectProps) => {
  const [selectedImage, setSelectedImage] =
    useState<ImageItem>(imagePlaceholder);
  const [isLoading, setIsLoading] = useState(false);

  const loadCardImage = () => {
    const image = getImageById(item.coverImageId);

    if (image.id === imagePlaceholder.id) {
      setIsLoading(true);
      getPiwigoImage(parseInt(item.coverImageId))
        .then((response) => {
          setSelectedImage({
            alt: response.result.name,
            id: response.result.id.toString(),
            src: response.result.derivatives.small.url,
          });
          setIsLoading(false);
        })
        .catch(() => {
          handlePiwigoFailedRequest();
          setIsLoading(false);
        });
    }

    setSelectedImage(image);
  };

  useEffect(() => {
    loadCardImage();
  }, []); // eslint-disable-line

  return (
    <Card
      cover={<img src={selectedImage.src} alt={selectedImage.alt} />}
      className="project-teaser"
      loading={isLoading}
    >
      <Meta
        title={item.title}
        description={
          <Link to={ROUTES.PROJECT.replace(":id", item.id)}>
            <Button
              type="primary"
              size="large"
              className="block-our-mission__button"
            >
              Consulter le projet
            </Button>
          </Link>
        }
      />
    </Card>
  );
};

export default ProjectTeaser;
