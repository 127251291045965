import { Button, Col, Divider, Image, Result, Row, Typography } from "antd";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { getPiwigoAlbumDate } from "../../getters/piwigoAlbum";
import useProject from "../../pages/Project/useProject";
import type { AlbumImageResponse, ImageItem } from "../../types";
import { PROJECT_DATE, PROJECT_DATE_DISPLAY } from "../../utils";
import { ROUTES } from "../../utils/enums";
import PageBanner from "../PageBanner";
import PageBreadcrumb from "../PageBreadcrumb";
import PageLayout from "../PageLayout";
import ProjectStatus from "../ProjectStatus";
import StandardPageBlock from "../StandardPageBlock";

type PiwigoProjectProps = {
  currentProject: AlbumImageResponse;
  projectImage: ImageItem;
};

const { Title, Text } = Typography;

const PiwigoProject = ({
  projectImage,
  currentProject,
}: PiwigoProjectProps) => {
  console.log("Project:", currentProject);

  const urlParam = useParams();

  const breadcrumbs = [
    { link: ROUTES.PROJECTS, value: "Projets" },
    { value: projectImage.alt },
  ];
  const { getPiwigoAlbumDetails } = useProject();
  const albumDetails = getPiwigoAlbumDetails(parseInt(urlParam?.id ?? "0"));

  const renderImageGroup = () => {
    return currentProject.result.images.map((image) => {
      return (
        <Image
          src={image.element_url}
          key={image.id}
          alt={`Image ${image.file}`}
        />
      );
    });
  };

  if (albumDetails === undefined) {
    return (
      <PageLayout>
        <Result
          status="404"
          title="Projet indisponible"
          extra={
            <Button type="primary">
              <Link to={ROUTES.HOMEPAGE}>Accueil</Link>
            </Button>
          }
        />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <>
        <PageBanner image={projectImage} title={projectImage.alt} />
        <PageBreadcrumb items={breadcrumbs} />
        <StandardPageBlock>
          <Row>
            <Col xs={24} md={14}>
              <img
                src={projectImage.src}
                alt={projectImage.alt}
                className="img-responsive"
              />
              <Title level={3} className="project__title">
                {projectImage.alt} <ProjectStatus status="EXECUTED" />
              </Title>
              <Divider />
            </Col>
            <Col xs={24}>
              <p>
                <Text type="secondary" className="text-capitalize">
                  {moment(
                    getPiwigoAlbumDate(albumDetails.id).date,
                    PROJECT_DATE
                  ).format(PROJECT_DATE_DISPLAY)}
                </Text>
              </p>
              <div
                className="project__description"
                dangerouslySetInnerHTML={{
                  __html: albumDetails.comment,
                }}
              />
              <div className="project__gallery">
                <Image.PreviewGroup>{renderImageGroup()}</Image.PreviewGroup>
              </div>
            </Col>
          </Row>
        </StandardPageBlock>
      </>
    </PageLayout>
  );
};

export default PiwigoProject;
