import { Col, Menu, Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import logoAtaajy from "../../assets/images/ataajy-logo.png";
import { MAIN_MENU, ROUTES } from "../../utils/enums";
import "./styles.less";

const { SubMenu } = Menu;

const MainMenu = () => {
  const [menuCurrentKey, setMenuCurrentKey] = useState(MAIN_MENU.NONE);

  const handleOnMenuClick = (e: any) => {
    setMenuCurrentKey(e.key);
  };

  return (
    <Row align="middle" className="mainmenu">
      <Link to={ROUTES.HOMEPAGE} className="mainmenu__logo">
        <img src={logoAtaajy} alt="Logo Ataajy" />
      </Link>
      <Col span={2} md={16} offset={8}>
        <Menu
          onClick={handleOnMenuClick}
          selectedKeys={[menuCurrentKey]}
          mode="horizontal"
        >
          <Menu.Item key={MAIN_MENU.ACTIVITIES}>Activités</Menu.Item>
          <SubMenu key={MAIN_MENU.PROJECTS} title="Projets">
            <Menu.Item key={MAIN_MENU.PROJECTS_DONE}>
              <Link to={ROUTES.PROJECTS}>Projets effectués</Link>
            </Menu.Item>
            <Menu.Item key={MAIN_MENU.PROJECTS_ONGOING}>
              Projets en cours
            </Menu.Item>
            <Menu.Item key={MAIN_MENU.PROJECTS_COMING}>
              Projets à venir
            </Menu.Item>
          </SubMenu>
          <Menu.Item key={MAIN_MENU.ABOUT_US}>À propos de nous</Menu.Item>
          <Menu.Item key={MAIN_MENU.CONTACT}>Contact</Menu.Item>
        </Menu>
      </Col>
    </Row>
  );
};

export default MainMenu;
