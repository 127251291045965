import StandardPageBlock from "../StandardPageBlock";
import "./styles.less";

type PageBlockWithBgProps = {
  children: JSX.Element;
};

const PageBlockWithBg = ({ children }: PageBlockWithBgProps) => (
  <div className="page-block-with-bg">
    <StandardPageBlock>{children}</StandardPageBlock>
  </div>
);

export default PageBlockWithBg;
