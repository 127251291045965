import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  Row,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import AtaajyBankAccount from "../../components/AtaajyBankAccount";
import PageBanner from "../../components/PageBanner";
import PageLayout from "../../components/PageLayout";
import StandardPageBlock from "../../components/StandardPageBlock";
import { getImageById } from "../../getters/images";
import { setPageTitle } from "../../utils";
import "./styles.less";

const Donate = () => {
  const selectedImage = getImageById("1645561116410");

  const handleOnFinish = () => {};

  const dropDownOverlay = (
    <Menu>
      <Menu.Item>
        <Form layout="inline" onFinish={handleOnFinish}>
          <Form.Item
            name="amount"
            rules={[
              { required: true, message: "Veuillez entrer un montant !" },
            ]}
          >
            <InputNumber addonAfter="€" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Je valide
            </Button>
          </Form.Item>
        </Form>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setPageTitle("Faites un don");
  }, []);

  return (
    <div className="donate">
      <PageLayout>
        <>
          <PageBanner
            image={getImageById("1645557540735")}
            title="Faites Un Don"
          />
          <StandardPageBlock>
            <Row>
              <Col xs={24} md={16}>
                <img
                  src={selectedImage.src}
                  alt={selectedImage.alt}
                  className="img-responsive"
                />
                <p className="donate__description">
                  Vous pouvez faire un virement sur notre compte.
                </p>
                <p>
                  <AtaajyBankAccount />
                </p>
                <Divider />
                <p className="donate__description">
                  Vous pouvez nous faire un don facilement et en sécurité avec
                  votre compte PayPal.
                </p>
                <Row>
                  <Col span={24} md={12}>
                    <p>
                      <Button
                        type="primary"
                        shape="round"
                        size="large"
                        href="https://www.paypal.com/paypalme/ataajy/15"
                        target="_blank"
                      >
                        Faire un don de 15 €
                      </Button>
                    </p>
                    <p>
                      <Button
                        type="primary"
                        shape="round"
                        size="large"
                        href="https://www.paypal.com/paypalme/ataajy/25"
                        target="_blank"
                      >
                        Faire un don de 25 €
                      </Button>
                    </p>
                    <p>
                      <Button
                        type="primary"
                        shape="round"
                        size="large"
                        href="https://www.paypal.com/paypalme/ataajy/50"
                        target="_blank"
                      >
                        Faire un don de 50 €
                      </Button>
                    </p>
                  </Col>
                  {/*<Col span={24} md={12}>
                    <Dropdown.Button
                      overlay={dropDownOverlay}
                      icon={<DownOutlined />}
                      trigger={["click"]}
                    >
                      Faire un don de
                    </Dropdown.Button>
                  </Col>*/}
                </Row>
              </Col>
            </Row>
          </StandardPageBlock>
        </>
      </PageLayout>
    </div>
  );
};

export default Donate;
