import { Button, Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { STANDARD_GUTTER } from "../../utils";
import { ROUTES } from "../../utils/enums";
import BlockHeader from "../BlockHeader";
import "./styles.less";

const { Text } = Typography;

const BlockAboutUs = () => {
  return (
    <Row className="block-about-us" gutter={STANDARD_GUTTER * 2} align="middle">
      <Col md={12} className="block-about-us__text">
        <BlockHeader
          preTitle="Qui sommes-nous"
          title="Nous aidons les marginalisés"
        />
        <p>
          <Text type="secondary">
            Notre mission est d'aider les oubliés et de redonner ce que nous
            avons reçu.
          </Text>
        </p>
        <p className="block-about-us__button">
          <Button type="primary" size="large">
            <Link to={ROUTES.DONATE}>Aidez-nous</Link>
          </Button>
        </p>
      </Col>
      <Col md={12} className="block-about-us__image"></Col>
    </Row>
  );
};

export default BlockAboutUs;
