import { Typography } from "antd";
import { ImageItem } from "../../types/main";
import "./styles.less";

const { Title } = Typography;

type PageBannerProps = {
  image: ImageItem;
  title: string;
};

const PageBanner = ({ image, title }: PageBannerProps) => (
  <div className="page-banner" style={{ backgroundImage: `url(${image.src})` }}>
    <div className="page-banner__darker">
      <div className="page-banner__title-container">
        <Title level={2}>{title}</Title>
      </div>
    </div>
  </div>
);

export default PageBanner;
