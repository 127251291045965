import { useEffect } from "react";
import BlockAboutUs from "../../components/BlockAboutUs";
import BlockNews from "../../components/BlockNews";
import BlockOurMission from "../../components/BlockOurMission";
import HomepageSlider from "../../components/HomepageSlider";
import PageBlockWithBg from "../../components/PageBlockWithBg";
import PageLayout from "../../components/PageLayout";
import StandardPageBlock from "../../components/StandardPageBlock";
import SummaryInNumbers from "../../components/SummaryInNumbers";
import { setPageTitle } from "../../utils";

const Home = () => {
  useEffect(() => {
    setPageTitle("Accueil");
  }, []); // eslint-disable-line

  return (
    <div className="homepage">
      <PageLayout>
        <>
          <HomepageSlider />
          <StandardPageBlock>
            <BlockAboutUs />
          </StandardPageBlock>
          <PageBlockWithBg>
            <BlockOurMission />
          </PageBlockWithBg>
          <StandardPageBlock>
            <SummaryInNumbers />
          </StandardPageBlock>
          <PageBlockWithBg>
            <BlockNews />
          </PageBlockWithBg>
        </>
      </PageLayout>
    </div>
  );
};

export default Home;
