import { Col, Divider, Image, Row, Typography } from "antd";
import type { BreadcrumbItemType, ImageItem, ProjectItem } from "../../types";
import PageBanner from "../PageBanner";
import PageBreadcrumb from "../PageBreadcrumb";
import PageLayout from "../PageLayout";
import StandardPageBlock from "../StandardPageBlock";
import ProjectStatus from "../ProjectStatus";
import moment from "moment";
import { PROJECT_DATE, PROJECT_DATE_DISPLAY } from "../../utils";
import { getImageById } from "../../getters/images";

const { Title, Text } = Typography;

type LocalProjectProps = {
  currentProject: ProjectItem;
  projectImage: ImageItem;
  breadcrumbs: BreadcrumbItemType[];
};

const LocalProject = ({
  projectImage,
  currentProject,
  breadcrumbs,
}: LocalProjectProps) => {
  const renderImageGroup = (project: ProjectItem) => {
    return project.images.map((imgId) => {
      let foundImage = getImageById(imgId);

      return (
        <Image src={foundImage.src} key={foundImage.id} alt={foundImage.alt} />
      );
    });
  };

  return (
    <PageLayout>
      <>
        <PageBanner image={projectImage} title={currentProject.title} />
        <PageBreadcrumb items={breadcrumbs} />
        <StandardPageBlock>
          <Row>
            <Col xs={24} md={14}>
              <img
                src={projectImage.src}
                alt={projectImage.alt}
                className="img-responsive"
              />
              <Title level={3} className="project__title">
                {currentProject.title}{" "}
                <ProjectStatus status={currentProject.status} />
              </Title>
              <Divider />
            </Col>
            <Col xs={24}>
              <p>
                <Text type="secondary" className="text-capitalize">
                  {moment(currentProject.date, PROJECT_DATE).format(
                    PROJECT_DATE_DISPLAY
                  )}
                </Text>
              </p>
              <div
                className="project__description"
                dangerouslySetInnerHTML={{
                  __html: currentProject.description,
                }}
              />
              <div className="project__gallery">
                <Image.PreviewGroup>
                  {renderImageGroup(currentProject)}
                </Image.PreviewGroup>
              </div>
            </Col>
          </Row>
        </StandardPageBlock>
      </>
    </PageLayout>
  );
};

export default LocalProject;
