import { Col, Row, Spin } from "antd";
import React from "react";
import { ALBUM_DATES } from "../../constants";
import { STANDARD_GUTTER } from "../../utils";
import BlockHeader from "../BlockHeader";
import ProjectTeaser from "../ProjectTeaser";
import "./styles.less";
import useProjects from "../../pages/Projects/useProjects";

const BlockOurMission = () => {
  const { isLoadingPiwigoAlbums, piwigoAlbums } = useProjects();

  const getProjectList = () => {
    if (piwigoAlbums) {
      return piwigoAlbums.result.categories.map((category) => ({
        id: category.id.toString(),
        coverImageId: category.representative_picture_id,
        title: category.name,
        description: category.comment,
        date: ALBUM_DATES[category.id],
        images: [],
        status: "EXECUTED",
        albumId: category.id,
      }));
    }

    return [];
  };

  const renderAlbums = () => {
    if (isLoadingPiwigoAlbums) {
      return (
        <Col span={24} style={{ textAlign: "center" }}>
          <Spin size="large" />
        </Col>
      );
    }

    return getProjectList().map((project, i) => {
      if (i < 3) {
        return (
          <Col xs={24} md={8} key={project.id}>
            <ProjectTeaser item={project} />
          </Col>
        );
      }

      return <React.Fragment key={i}></React.Fragment>;
    });
  };

  return (
    <div className="block-our-mission">
      <div className="text-center">
        <BlockHeader
          preTitle="Nos récentes missions"
          title="Consultez nos réalisations"
        />
      </div>
      <Row gutter={STANDARD_GUTTER * 2}>{renderAlbums()}</Row>
    </div>
  );
};

export default BlockOurMission;
