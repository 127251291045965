import { Button, Card } from "antd";
import { Link } from "react-router-dom";
import usePiwigoImage from "../../hooks/usePiwigoImage";
import useProject from "../../pages/Project/useProject";
import type { PiwigoAlbumsResponseCategory } from "../../types";
import { ROUTES } from "../../utils/enums";
import "./styles.less";

const { Meta } = Card;

type PiwigoAlbumTeaserProps = {
  item: PiwigoAlbumsResponseCategory;
};

const PiwigoAlbumTeaser = ({ item }: PiwigoAlbumTeaserProps) => {
  const { isLoadingPiwigoImage, piwigoImage } = usePiwigoImage(
    parseInt(item.representative_picture_id)
  );
  const { getPiwigoAlbumDetails } = useProject();
  const albumDetails = getPiwigoAlbumDetails(item.id);

  return (
    <Card
      cover={
        <img
          src={piwigoImage?.result.element_url}
          alt={piwigoImage?.result.name}
        />
      }
      className="project-teaser"
      loading={isLoadingPiwigoImage}
    >
      <Meta
        title={albumDetails?.name ?? "Album Inconnu"}
        description={
          <Link to={ROUTES.PROJECT.replace(":id", item.id.toString())}>
            <Button
              type="primary"
              size="large"
              className="block-our-mission__button"
            >
              Consulter le projet
            </Button>
          </Link>
        }
      />
    </Card>
  );
};

export default PiwigoAlbumTeaser;
