import { useEffect } from "react";
import PageBanner from "../../components/PageBanner";
import PageBreadcrumb from "../../components/PageBreadcrumb";
import PageLayout from "../../components/PageLayout";
import StandardPageBlock from "../../components/StandardPageBlock";
import { getImageById } from "../../getters/images";
import { setPageTitle, STANDARD_GUTTER } from "../../utils";
import { ROUTES } from "../../utils/enums";
import projectsCompleted from "../../assets/data/projectsCompleted.json";
import ProjectTeaser from "../../components/ProjectTeaser";
import { Col, Row } from "antd";
import useProjects from "./useProjects";
import PiwigoAlbumTeaser from "../../components/PiwigoAlbumTeaser";

const breadcrumbs = [{ link: ROUTES.PROJECTS, value: "Projets" }];

const Projects = () => {
  const { piwigoAlbums } = useProjects();

  useEffect(() => {
    setPageTitle("Projets");
  }, []); // eslint-disable-line

  return (
    <div className="projects">
      <PageLayout>
        <>
          <PageBanner
            image={getImageById("1646455366852")}
            title="Nos projets"
          />
          <PageBreadcrumb items={breadcrumbs} />
          <StandardPageBlock>
            <Row gutter={STANDARD_GUTTER * 3}>
              {piwigoAlbums?.result.categories.map((category) => (
                <Col key={category.id} xs={24} md={12}>
                  <PiwigoAlbumTeaser item={category} key={category.id} />
                </Col>
              ))}
              {projectsCompleted.map((project) => (
                <Col key={project.id} xs={24} md={12}>
                  <ProjectTeaser item={project} />
                </Col>
              ))}
            </Row>
          </StandardPageBlock>
        </>
      </PageLayout>
    </div>
  );
};

export default Projects;
