import { Col, Row } from "antd";

type StandardPageBlockProps = {
  children: JSX.Element;
};

const StandardPageBlock = ({ children }: StandardPageBlockProps) => (
  <Row className="page-block-spacing">
    <Col xs={1} md={6} />
    <Col xs={22} md={12}>
      {children}
    </Col>
  </Row>
);

export default StandardPageBlock;
