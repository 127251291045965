import { Tag } from "antd";
import { PROJECT_STATUS } from "../../utils/enums";

type ProjectStatusProps = {
  status: string;
};

const ProjectStatus = ({ status }: ProjectStatusProps) => {
  const renderStatus = () => {
    switch (status) {
      case PROJECT_STATUS.EXECUTED:
        return <Tag color="green">projet réalisé</Tag>;

      default:
        return <Tag>{status}</Tag>;
    }
  };

  return renderStatus();
};

export default ProjectStatus;
