import { Col, Row } from "antd";
import { BreadcrumbItemType } from "../../types/main";
import AtaajyBreadcrumb from "../AtaajyBreadcrumb";
import "./styles.less";

type AtaajyBreadcrumbProps = {
  items: BreadcrumbItemType[];
};

const PageBreadcrumb = ({ items }: AtaajyBreadcrumbProps) => (
  <Row className="pagebreadcrumb">
    <Col xs={1} md={6} />
    <Col xs={22} md={12}>
      <AtaajyBreadcrumb items={items} />
    </Col>
  </Row>
);

export default PageBreadcrumb;
