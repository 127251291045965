import { Spin } from "antd";
import "./styles.less";

const LoaderAnimation = () => {
  return (
    <div className="loaderanimation">
      <Spin size="large" />
    </div>
  );
};

export default LoaderAnimation;
