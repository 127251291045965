import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { ROUTES } from "../../utils/enums";
import { BreadcrumbItemType } from "../../types/main";

type AtaajyBreadcrumbProps = {
  items: BreadcrumbItemType[];
};

const AtaajyBreadcrumb = ({ items }: AtaajyBreadcrumbProps) => {
  const list = items.map((item, i) => {
    if (i === items.length - 1) {
      return <Breadcrumb.Item key={i}>{item.value}</Breadcrumb.Item>;
    }

    return (
      <Breadcrumb.Item key={i}>
        <Link to={item.link || ""}>{item.value}</Link>
      </Breadcrumb.Item>
    );
  });

  return (
    <Breadcrumb separator=">">
      <Breadcrumb.Item>
        <Link to={ROUTES.HOMEPAGE}>
          <HomeOutlined />
        </Link>
      </Breadcrumb.Item>
      {list}
    </Breadcrumb>
  );
};

export default AtaajyBreadcrumb;
