export enum ROUTES {
  HOMEPAGE = "/",
  PROJECTS = "/projets",
  PROJECT = "/projet/:id",
  DONATE = "/faites-un-don",
}

export enum MAIN_MENU {
  ABOUT_US = "about-us",
  ACTIVITIES = "activities",
  CONTACT = "contact",
  NONE = "",
  PROJECTS = "projects",
  PROJECTS_DONE = "projects-done",
  PROJECTS_ONGOING = "projects-ongoing",
  PROJECTS_COMING = "projects-coming",
}

export enum PROJECT_STATUS {
  EXECUTED = "EXECUTED",
}
