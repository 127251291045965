import { Route, Routes } from "react-router-dom";
import moment from "moment";
import Donate from "./pages/Donate";
import Home from "./pages/Home";
import Project from "./pages/Project";
import Projects from "./pages/Projects";
import { ROUTES } from "./utils/enums";
import "moment/locale/fr";
import "./App.less";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

moment.locale("fr");

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <div className="ataajy-app">
        <Routes>
          <Route path={ROUTES.HOMEPAGE} element={<Home />} />
          <Route path={ROUTES.PROJECTS} element={<Projects />} />
          <Route path={ROUTES.PROJECT} element={<Project />} />
          <Route path={ROUTES.DONATE} element={<Donate />} />
        </Routes>
      </div>
    </QueryClientProvider>
  );
};

export default App;
